import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import ContactSection from '../components/ContactSection/ContactSection';

const Register = ({ data }) => {
  return (
    <Layout>
      <ContactSection block={data.sanityTexts._rawRegister} askType={true} />
    </Layout>
  );
};

export const query = graphql`
  {
    sanityTexts(_id: { eq: "texts" }) {
      _rawRegister(resolveReferences: { maxDepth: 10 })
    }
  }
`;

export default Register;
